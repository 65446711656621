import { render, staticRenderFns } from "./PublicCasePDFPassword.vue?vue&type=template&id=4386e673&scoped=true&"
import script from "./PublicCasePDFPassword.vue?vue&type=script&lang=js&"
export * from "./PublicCasePDFPassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4386e673",
  null
  
)

export default component.exports