<template>
  <div>
    <b-row>
      <b-col>
        <h2>Public Case PDF Password</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <b-form-group
          label="Password"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="Password"
            :readonly="readOnly"
            placeholder=""
            name="password"
          />
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 1.5rem;">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
          :disabled="!Password"
          @click="update()"
        >
          Update
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      v-model="modalShow"
      hide-backdrop
      size="md"
      centered
    >
      <template #modal-header="">
        <div class="d-flex mt-1 mb-n3 text-center flex-column justify-content-center">
          <h2 class="text-center">
            <strong>You are about to change the Public Case PDF Password.</strong>
          </h2>
          <h3>Are you sure you want to do this?</h3>
        </div>
      </template>

      <template #modal-footer="">
        <div class="d-flex mb-1 justify-content-center flex-grow-1">
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="reset"
              variant="outline-secondary"
              size="md"
              @click="hideChangeModal()"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-50 d-flex justify-content-center">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              size="md"
              @click="changePassword()"
            >
              Change Password
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      Password: "",
      readOnly: false,
      modalShow: false,
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    showChangeModal() {
      this.modalShow = true;
    },
    hideChangeModal() {
      this.modalShow = false;
    },
    changePassword() {
      const postData = {
        password: this.Password,
      };
      apiService.post("public/case/pdf-password", postData).then(() => this.modalShow = false)
    },
    update() {
      this.modalShow = true;
    },
  }
}
</script>

<style scoped>

</style>